import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>
        An average of{" "}
        <a
          href="https://www.givewell.org/cost-to-save-a-life"
          target="_blank"
          rel="noreferrer"
        >
          $4,400 USD
        </a>{" "}
        will save 1 life.
      </h1>
      <h2>
        How? By buying{" "}
        <a
          href="https://www.givewell.org/charities/amf#Does_it_work"
          target="_blank"
          rel="noreferrer"
        >
          bug nets
        </a>{" "}
        for people in guinea.
      </h2>
      <div className="desc">
        <a href="https://againstmalaria.com" target="blank" rel="noreferrer">
          Against Malaria Foundation (AMF)
        </a>{" "}
        provides funding for{" "}
        <a
          href="https://www.givewell.org/international/technical/programs/insecticide-treated-nets"
          target="_blank"
          rel="noreferrer"
        >
          long-lasting insecticide-treated net (LLIN)
        </a>{" "}
        distributions (for protection against malaria) in developing countries.
        <br />
        <br />
        <a
          href="https://www.givewell.org/charities/amf#What_do_they_do"
          target="_blank"
          rel="noreferrer"
        >
          See more.
        </a>
      </div>
      <div className="btn-container">
        <a
          className="save-life-btn"
          href="https://secure.givewell.org/"
          target="_blank"
          rel="noreferrer"
        >
          Save a Life
        </a>
      </div>

      <div className="button-desc">
        this button will redirect you to givewell.org to process the donation
      </div>
      <footer>
        Made with {"<3"} by{" "}
        <a href="https://ae.studio" target="_blank" rel="noreferrer">
          Agency Enterprise.
        </a>{" "}
        <br />
        We donate 5% of our profit every month to{" "}
        <a
          href="https://ae.studio/#giving-back"
          target="_blank"
          rel="noreferrer"
        >
          save lives.
        </a>
      </footer>
    </div>
  );
}

export default App;
